<template>
    <div>
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <DashCard title="Estados de SIAs" :actions="true" actions-align="right">
                    <v-skeleton-loader type="image" :loading="index_loading">
                        <ChartResumenSia :datos="sias_grafico.grafico" height="250" />
                    </v-skeleton-loader>
                    <template v-slot:actions>
                        <v-btn text depressed large color="primary" type="submit" :to="{ name: 'dashboard_sias' }">
                            <v-icon>inbox</v-icon>
                            <span class="ml-2 subtitle-2">Ver SIAS</span>
                        </v-btn>
                    </template>
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6" md="8">
                <DashCard title="Seguimiento y trazabilidad" :actions="true" actions-align="right">                   
                    <v-skeleton-loader type="image" :loading="index_loading">
                        <v-row dense>
                            <v-col cols="12" sm="6" lg="4" v-for="(item, index) in incidente_porcentajes" v-bind:key="index">
                                <ProgressCircle :title="item.nombre" v-model="item.valor" :color="item.vue_color" />
                            </v-col>
                        </v-row>
                    </v-skeleton-loader>
                    <template v-slot:actions>
                        <v-btn text depressed large color="primary" type="submit" :to="{ name: 'dashboard_incidentes' }">
                            <v-icon>inbox</v-icon>
                            <span class="ml-2 subtitle-2">Ver Incidentes</span>
                        </v-btn>
                    </template>
                </DashCard>
            </v-col>
            <v-col cols="12" md="12" lg="6">
                <DashCard title="Liderazgos con tareas en tratamiento" body-class="pa-0" :actions="true" actions-align="right">
                    <v-skeleton-loader type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line" :loading="index_loading">
                        <ListaLV :datos="lv_top_lista" />
                    </v-skeleton-loader>
                    <template v-slot:actions>
                        <v-btn text depressed large color="primary" type="submit" :to="{ name: 'dashboard_liderazgos' }">
                            <v-icon>inbox</v-icon>
                            <span class="ml-2 subtitle-2">Ver Compromisos seguridad</span>
                        </v-btn>
                    </template>
                </DashCard>
            </v-col>
            <v-col cols="12" md="12" lg="6" v-if="$parent.$parent.user.groups.includes('supers')">
                <DashCard title="Pronóstico MP10" title-icon="timeline" body-class="pa-0" :actions="true" actions-align="right">
                    <div class="text-center">
                        <v-btn-toggle mandatory class="pa-2" @change="load_chart">
                            <v-btn value="mp10">
                                <span class="subtitle-2">MP10</span>
                            </v-btn>
                            <v-btn value="wind">
                                <span class="subtitle-2"><v-icon left>toys</v-icon>Viento</span>
                            </v-btn>
                            <v-btn value="temp">
                                <span class="subtitle-2"><v-icon left>whatshot</v-icon>Temp.</span>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                    <v-divider />
                    <EmptyCard class="ma-4" v-if="meteochart_error" text="Los pronósticos no están disponibles en este momento" icon="error_outline" />
                    <v-skeleton-loader v-else type="image, image" :loading="meteochart_loading">
                        <ChartPronostico :height="350" :series="meteodata_series" />
                    </v-skeleton-loader>
                    <template v-slot:actions>
                        <v-btn text depressed large color="primary" type="submit" :to="{ name: 'dashboard_meteodata_v1' }">
                            <v-icon>timeline</v-icon>
                            <span class="ml-2 subtitle-2">Ver Pronóstico Semanal</span>
                        </v-btn>
                    </template>
                </DashCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DashCard         from '@/shared/components/DashCard'
import EmptyCard        from '@/shared/components/EmptyCard'
import ChartResumenSia  from '@/dash/components/ChartResumenSia'
import ChartArea        from '@/dash/components/ChartArea'
import ChartPronostico  from '@/dash/components/ChartPronostico'
import ProgressCircle   from '@/dash/components/ProgressCircle'
import ListaLV          from '@/dash/components/ListaLV'
import { axiosHelper }  from '@/helpers'
import moment           from 'moment'

export default {
    components: {
        DashCard,
        EmptyCard,
        ListaLV,
        ProgressCircle,
        ChartResumenSia,
        ChartArea,
        ChartPronostico
    },
    data: () => ({
        index: {},
        meteochart_error: false,
        grafico: null
    }),
    computed: {
        index_loading() {
            return !Object.values(this.index).length;
        },
        meteochart_loading() {
            return !this.grafico;
        },
        sias_grafico() {
            return this.index.resumen_sias || {};
        },
        incidente_porcentajes() {
            if(!this.index.resumen_incidentes) return [];
            return this.index.resumen_incidentes.porcentajes || [];
        },
        lv_top() {
            return this.index.lv_pendientes || {};
        },
        lv_top_lista() {
            if(!this.lv_top) return [];
            if(!this.lv_top.listado) return [];
            
            let top = this.lv_top.listado;
            top.sort((a, b) => {
                return moment(a.tareas[0].vencimiento).diff(moment());
            });
            return top;
        },
        meteodata_series() {
            if(!this.grafico) return [];

            let series = this.grafico.series;
            series.forEach(s => s.type = 'line');
            return series;
        }
    },
    methods: {
        show_dialog_version() {
            this.$refs.dialog_version.show();
        },
        load_chart(tipo) {
            this.grafico = null;
            axiosHelper.post('meteodata/pronosticos/grafico/', { 'variable': tipo })
                .then(response => this.grafico = response.data)
                .catch(error => {
                    // this.meteochart_error = false;
                    this.meteochart_error = true;
                    this.grafico = null;
                });
        }
    },
    created() {
        this.$emit('title', { text: "Dashboard", icon: "dashboard" });
        axiosHelper.get('dashboard/')
            .then(response => this.index = response.data);
            
        if(this.$parent.$parent.user.groups.includes('supers')) {
            this.load_chart('mp10');
        }
    }
}
</script>